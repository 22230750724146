import React from "react"
import ContactUs from "../components/ContactUs"
import {News,archived_news_data} from '../components/NewsBox';
import { Link } from 'gatsby';

import Layout from "../components/layout"
import PageHeader from "../components/PageHeader"

class SecondPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      compact: false,
    }
  }
  toggle(action) {
    this.setState({
      compact: !!action,
    })
  }

  render() {
    return (
      <Layout>
        <PageHeader title="News &amp; Events" subtitle="Stay updated with our latest news and company updates" />
        <section className="section-padding-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mobile-margin-top">
                <div className="col-limit-3">
                  <div className="heading-top"></div>
                  <h2 className="heading-2 mb-5">
                    VoerEir Over The Years
                  </h2>
                </div>
              </div>
              {/*<div className="col-lg-12 button-box mb-5">
                <span className="small">change layout:</span>
                <button
                  className="ml-2 svg-button"
                  onClick={e => this.toggle(false)}
                >
                  <ListIcon
                    className={`${this.state.compact ? "opacity" : ""}`}
                  />
                </button>
                <button className="ml-2 svg-button" onClick={e => this.toggle(true)}>
                  <TileIcon
                    className={`${this.state.compact ? "" : "opacity"}`}
                  />
                </button>
    </div>*/}
            </div>
            <div className="news-btn">
              <Link to="/news" className="btn btn-outline-dark margin-btn">
                Latest news
              </Link>
              <Link className="btn btn-dark margin-btn" to="/news-archived">
                Archive
              </Link>
            </div>
            <div className="main-media compact">
              {archived_news_data.map((o,i)=>(<News key={i} {...o} />))}
            </div>
          </div>
        </section>
        <ContactUs />
      </Layout>
    )
  }
}

export default SecondPage

